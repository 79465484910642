exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journey-tsx": () => import("./../../../src/pages/journey.tsx" /* webpackChunkName: "component---src-pages-journey-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-9-kicks-shop-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/9kicks-shop.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-9-kicks-shop-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-binary-face-classifier-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/binary-face-classifier.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-binary-face-classifier-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-chat-application-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/chat-application.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-chat-application-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-job-listing-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/job-listing.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-job-listing-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-mirco-blog-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/mirco-blog.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-mirco-blog-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-notes-app-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/notes-app.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-notes-app-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-personal-website-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/personal-website.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-personal-website-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-python-photo-transfer-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/python-photo-transfer.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-python-photo-transfer-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-sales-system-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/sales-system.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-sales-system-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-task-tracker-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/task-tracker.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-task-tracker-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-tictactoe-react-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/tictactoe-react.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-tictactoe-react-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-todo-list-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/todo-list.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-todo-list-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-weather-data-vis-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/content/projects/weather-data-vis.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-src-content-projects-weather-data-vis-mdx" */),
  "component---src-pages-videography-tsx": () => import("./../../../src/pages/videography.tsx" /* webpackChunkName: "component---src-pages-videography-tsx" */)
}

